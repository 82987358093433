export const INIT_PACK = 'INIT_PACK';

export const FETCH_PACK = 'FETCH_PACK';
export const FETCH_PACK_SUCCESS = 'FETCH_PACK_SUCCESS';
export const FETCH_PACK_FAILED = 'FETCH_PACK_FAILED';

export const CREATE_PACK = 'CREATE_PACK';
export const CREATE_PACK_SUCCESS = 'CREATE_PACK_SUCCESS';
export const CREATE_PACK_FAILED = 'CREATE_PACK_FAILED';

export const UPDATE_PACK = 'UPDATE_PACK';
export const UPDATE_PACK_SUCCESS = 'UPDATE_PACK_SUCCESS';
export const UPDATE_PACK_FAILED = 'UPDATE_PACK_FAILED';

export const DELETE_PACK = 'DELETE_PACK';
export const DELETE_PACK_SUCCESS = 'DELETE_PACK_SUCCESS';
export const DELETE_PACK_FAILED = 'DELETE_PACK_FAILED';

export const SPLIT_PACK = 'SPLIT_PACK';

export const ASSIGN_PACK_GROSS_WEIGHT = 'ASSIGN_PACK_GROSS_WEIGHT';
export const ASSIGN_PACK_HEIGHT = 'ASSIGN_PACK_HEIGHT';
export const ASSIGN_PACK_LENGTH = 'ASSIGN_PACK_LENGTH';
export const ASSIGN_PACK_WIDTH = 'ASSIGN_PACK_WIDTH';

export const ASSIGN_PACK_DOMESTIC_WAYBILL_NUMBER = 'ASSIGN_PACK_DOMESTIC_WAYBILL_NUMBER';
export const ASSIGN_PACK_DOMESTIC_WAYBILL_ITEM_NUMBER = 'ASSIGN_PACK_DOMESTIC_WAYBILL_ITEM_NUMBER';
export const ASSIGN_PACK_ITEM_DESCRIPTION = 'ASSIGN_PACK_ITEM_DESCRIPTION';
export const ASSIGN_PACK_QUANTITY = 'ASSIGN_PACK_QUANTITY';
export const ASSIGN_PACK_UNIT = 'ASSIGN_PACK_UNIT';
export const ASSIGN_PACK_RECIPIENT = 'ASSIGN_PACK_RECIPIENT';
export const ASSIGN_PACK_RECIPIENT_MOBILE = 'ASSIGN_PACK_RECIPIENT_MOBILE';
export const ASSIGN_PACK_RECIPIENT_ADDRESS = 'ASSIGN_PACK_RECIPIENT_ADDRESS';
export const ASSIGN_PACK_NOTE = 'ASSIGN_PACK_NOTE';
export const ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_NUMBER = 'ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_NUMBER';
export const ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_POSITION_NUMBER = 'ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_POSITION_NUMBER';
export const ASSIGN_PACK_IS_REMOVE_PACKAGE = 'ASSIGN_PACK_IS_REMOVE_PACKAGE';
export const ASSIGN_PACK_ARRIVED_AT = 'ASSIGN_PACK_ARRIVED_AT';
export const ASSIGN_PACK_VARIETY = 'ASSIGN_PACK_VARIETY';

export const ASSIGN_PACK_CLIENT = 'ASSIGN_PACK_CLIENT';
