import { api, extractIdFrom, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_PACK_API,
  DELETE_PACK_API,
  DOWNLOAD_UNCLAIMED_PACKS_CSV_API,
  FETCH_PACK_API,
  INDEX_PACKS_API,
  SPLIT_PACK_API,
  UPDATE_PACK_API
} from "api/pack/constants";
import _ from 'lodash';

export const apiListPacks = ({ page, query }) => {
  const { url, method } = INDEX_PACKS_API;

  return api({
    method,
    url,
    params: {
      page,
      query,
    }
  });
};

export const apiCreatePack = ({ pack }) => {
  const { url, method } = CREATE_PACK_API;

  return api({
    method,
    url,
    data: packParams(pack),
  });
};

export const apiFetchPack = ({ packId }) => {
  const { url, method } = FETCH_PACK_API;

  return api({
    method,
    url: generateRoute(url, packId),
  });
};

export const apiUpdatePack = ({ pack }) => {
  const { url, method } = UPDATE_PACK_API;

  return api({
    method,
    url: generateRoute(url, pack.id),
    data: packParams(pack),
  });
};

export const apiDeletePack = ({ pack }) => {
  const { url, method } = DELETE_PACK_API;

  return api({
    method,
    url: generateRoute(url, pack.id),
  });
};

export const apiSplitPack = ({ pack, newPacks }) => {
  const { url, method } = SPLIT_PACK_API;

  return api({
    method,
    url: generateRoute(url, pack.id),
    data: {
      packs: _.map(newPacks, ({ domesticWaybillNumber, domesticWaybillItemNumber }) => {
        return {
          domesticWaybillNumber,
          domesticWaybillItemNumber,
        }
      }),
    }
  });
};

export const apiDownloadUnclaimedPacksCsv = () => {
  const { url, method, responseType } = DOWNLOAD_UNCLAIMED_PACKS_CSV_API;

  return api({
    method,
    url,
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

const packParams = (pack) => {
  const {
    domesticWaybillNumber,
    domesticWaybillItemNumber,
    itemDescription,
    quantity,
    unit,
    recipient,
    recipientMobile,
    recipientAddress,
    grossWeight,
    height,
    length,
    width,
    note,
    warehouseLocationShelfNumber,
    warehouseLocationShelfPositionNumber,
    isRemovePackage,
    arrivedAt,
    clientId,
    variety,
  } = pack;

  return {
    domesticWaybillNumber,
    domesticWaybillItemNumber,
    itemDescription,
    quantity,
    unit,
    recipient,
    recipientMobile,
    recipientAddress,
    grossWeight,
    height,
    length,
    width,
    note,
    warehouseLocationShelfNumber,
    warehouseLocationShelfPositionNumber,
    isRemovePackage,
    arrivedAt,
    clientId: extractIdFrom(clientId),
    variety,
  }
};
