import {all} from 'redux-saga/effects';
import packBatchUploadSaga from "redux/model/packBatchUploads/packBatchUpload/packBatchUploadSaga";
import orderPacksSaga from "redux/model/orderPacks/orderPacks/orderPacksSaga";
import clientSaga from "./clients/client/clientSaga";
import clientsSaga from "./clients/clients/clientsSaga";
import packSaga from "./packs/pack/packSaga";
import packsSaga from "./packs/packs/packsSaga";
import orderPackSaga from "redux/model/orderPacks/orderPack/orderPackSaga";
import lineItemSaga from "redux/model/lineItems/lineItem/lineItemSaga";
import lineItemsSaga from "redux/model/lineItems/lineItems/lineItemsSaga";
import exportDimensionBatchUploadSaga
  from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUpload/exportDimensionBatchUploadSaga";
import exportDimensionBatchUploadsSaga
  from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUploads/exportDimensionBatchUploadsSaga";
import exportInternationalBatchUploadSaga
  from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/exportInternationalBatchUploadSaga";
import exportInternationalBatchUploadsSaga
  from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUploads/exportInternationalBatchUploadsSaga";
import lineAttachmentSaga from "redux/model/lineAttachments/lineAttachment/lineAttachmentSaga";
import lineAttachmentsSaga from "redux/model/lineAttachments/lineAttachments/lineAttachmentsSaga";
import transactionSaga from "redux/model/transactions/transaction/transactionSaga";
import transactionsSaga from "redux/model/transactions/transactions/transactionsSaga";
import receivedNoticeSaga from "redux/model/receivedNotices/receivedNotice/receivedNoticeSaga";
import receivedNoticesSaga from "redux/model/receivedNotices/receivedNotices/receivedNoticesSaga";
import paymentSaga from "redux/model/payments/payment/paymentSaga";
import paymentsSaga from "redux/model/payments/payments/paymentsSaga";
import couponRuleSaga from "redux/model/couponRules/couponRule/couponRuleSaga";
import couponRulesSaga from "redux/model/couponRules/couponRules/couponRulesSaga";
import wechatNoticeSaga from "redux/model/wechatNotices/wechatNotice/wechatNoticeSaga";
import wechatNoticesSaga from "redux/model/wechatNotices/wechatNotices/wechatNoticesSaga";
import receiptAddressSaga from "redux/model/receiptAddresses/receiptAddress/receiptAddressSaga";
import receiptAddressesSaga from "redux/model/receiptAddresses/receiptAddresses/receiptAddressesSaga";


function* modelSaga() {
  yield all([
    packBatchUploadSaga(),
    orderPackSaga(),
    orderPacksSaga(),
    clientSaga(),
    clientsSaga(),
    packSaga(),
    packsSaga(),
    lineItemSaga(),
    lineItemsSaga(),
    exportDimensionBatchUploadSaga(),
    exportDimensionBatchUploadsSaga(),
    exportInternationalBatchUploadSaga(),
    exportInternationalBatchUploadsSaga(),
    lineAttachmentSaga(),
    lineAttachmentsSaga(),
    transactionSaga(),
    transactionsSaga(),
    receivedNoticeSaga(),
    receivedNoticesSaga(),
    paymentSaga(),
    paymentsSaga(),
    couponRuleSaga(),
    couponRulesSaga(),
    wechatNoticeSaga(),
    wechatNoticesSaga(),
    receiptAddressSaga(),
    receiptAddressesSaga(),
  ]);
}

export default modelSaga;
