import { combineReducers } from 'redux';
import packBatchUploadReducers from "redux/model/packBatchUploads/packBatchUpload/packBatchUploadReducers";
import orderPacksReducers from "redux/model/orderPacks/orderPacks/orderPacksReducers";
import clientReducers from "./clients/client/clientReducers";
import clientsReducers from "./clients/clients/clientsReducers";
import packReducers from "./packs/pack/packReducers";
import packsReducers from "./packs/packs/packsReducers";
import orderPackReducers from "redux/model/orderPacks/orderPack/orderPackReducers";
import lineItemReducers from "redux/model/lineItems/lineItem/lineItemReducers";
import lineItemsReducers from "redux/model/lineItems/lineItems/lineItemsReducers";
import exportDimensionBatchUploadReducers
  from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUpload/exportDimensionBatchUploadReducers";
import exportDimensionBatchUploadsReducers
  from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUploads/exportDimensionBatchUploadsReducers";
import exportInternationalBatchUploadReducers
  from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/exportInternationalBatchUploadReducers";
import exportInternationalBatchUploadsReducers
  from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUploads/exportInternationalBatchUploadsReducers";
import lineAttachmentReducers from "redux/model/lineAttachments/lineAttachment/lineAttachmentReducers";
import lineAttachmentsReducers from "redux/model/lineAttachments/lineAttachments/lineAttachmentsReducers";
import transactionReducers from "redux/model/transactions/transaction/transactionReducers";
import transactionsReducers from "redux/model/transactions/transactions/transactionsReducers";
import receivedNoticeReducers from "redux/model/receivedNotices/receivedNotice/receivedNoticeReducers";
import receivedNoticesReducers from "redux/model/receivedNotices/receivedNotices/receivedNoticesReducers";
import paymentReducers from "redux/model/payments/payment/paymentReducers";
import paymentsReducers from "redux/model/payments/payments/paymentsReducers";
import couponRuleReducers from "redux/model/couponRules/couponRule/couponRuleReducers";
import couponRulesReducers from "redux/model/couponRules/couponRules/couponRulesReducers";
import wechatNoticeReducers from "redux/model/wechatNotices/wechatNotice/wechatNoticeReducers";
import wechatNoticesReducers from "redux/model/wechatNotices/wechatNotices/wechatNoticesReducers";
import receiptAddressReducers from "redux/model/receiptAddresses/receiptAddress/receiptAddressReducers";
import receiptAddressesReducers from "redux/model/receiptAddresses/receiptAddresses/receiptAddressesReducers";


export default combineReducers({
  packBatchUpload: packBatchUploadReducers,
  orderPack: orderPackReducers,
  orderPacks: orderPacksReducers,
  client: clientReducers,
  clients: clientsReducers,
  pack: packReducers,
  packs: packsReducers,
  lineItem: lineItemReducers,
  lineItems: lineItemsReducers,
  exportDimensionBatchUpload: exportDimensionBatchUploadReducers,
  exportDimensionBatchUploads: exportDimensionBatchUploadsReducers,
  exportInternationalBatchUpload: exportInternationalBatchUploadReducers,
  exportInternationalBatchUploads: exportInternationalBatchUploadsReducers,
  lineAttachment: lineAttachmentReducers,
  lineAttachments: lineAttachmentsReducers,
  transaction: transactionReducers,
  transactions: transactionsReducers,
  receivedNotice: receivedNoticeReducers,
  receivedNotices: receivedNoticesReducers,
  payment: paymentReducers,
  payments: paymentsReducers,
  couponRule: couponRuleReducers,
  couponRules: couponRulesReducers,
  wechatNotice: wechatNoticeReducers,
  wechatNotices: wechatNoticesReducers,
  receiptAddress: receiptAddressReducers,
  receiptAddresses: receiptAddressesReducers,
});
