
export const CREATE_TRANSACTION_API = {
  url: `/transaction`,
  method: 'post'
};

export const INDEX_TRANSACTIONS_API = {
  url: `/transaction/base`,
  method: 'get',
};

export const FETCH_TRANSACTION_API = {
  url: `/transaction/base/:id`,
  method: 'get',
};

export const UPDATE_TRANSACTION_API = {
  url: `/transaction/:id`,
  method: 'put'
};

export const DELETE_TRANSACTION_API = {
  url: `/transaction/:id`,
  method: 'delete'
};

export const UPDATE_TRANSACTION_STATUS_API = {
  url: `/transaction/base/:id/update_status`,
  method: 'put'
};