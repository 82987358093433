import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_WECHAT_NOTICE_PATH, INDEX_WECHAT_NOTICES_PATH, NEW_WECHAT_NOTICE_PATH, SHOW_WECHAT_NOTICE_PATH } from "routes/wechatNotice/constants";

const WechatNotices = React.lazy(() => import('pages/wechatNotices/index/WechatNotices'));
const NewWechatNotice = React.lazy(() => import('pages/wechatNotices/new/NewWechatNotice'));
const ShowWechatNotice = React.lazy(() => import('pages/wechatNotices/show/ShowWechatNotice'));

export const wechatNoticeRoutes = {
  path: BASE_WECHAT_NOTICE_PATH,
  name: 'WechatNotices',
  icon: 'far fa-star',
  children: [
    {
      path: INDEX_WECHAT_NOTICES_PATH,
      exact: true,
      name: 'WechatNotices',
      component: WechatNotices,
      route: PrivateRoute,
    },
    {
      path: NEW_WECHAT_NOTICE_PATH,
      exact: true,
      name: 'Add New',
      component: NewWechatNotice,
      route: PrivateRoute,
    },
  ],
};

export const hiddenWechatNoticeRoutes = {
  children: [
    {
      path: SHOW_WECHAT_NOTICE_PATH,
      exact: true,
      name: 'Show WechatNotice',
      component: ShowWechatNotice,
      route: PrivateRoute,
    }
  ]
}
