import {
  ASSIGN_PACK_ARRIVED_AT,
  ASSIGN_PACK_CLIENT, ASSIGN_PACK_DOMESTIC_WAYBILL_ITEM_NUMBER, ASSIGN_PACK_DOMESTIC_WAYBILL_NUMBER,
  ASSIGN_PACK_GROSS_WEIGHT,
  ASSIGN_PACK_HEIGHT, ASSIGN_PACK_IS_REMOVE_PACKAGE, ASSIGN_PACK_ITEM_DESCRIPTION,
  ASSIGN_PACK_LENGTH,
  ASSIGN_PACK_NOTE, ASSIGN_PACK_QUANTITY, ASSIGN_PACK_RECIPIENT,
  ASSIGN_PACK_RECIPIENT_ADDRESS,
  ASSIGN_PACK_RECIPIENT_MOBILE, ASSIGN_PACK_UNIT, ASSIGN_PACK_VARIETY,
  ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_NUMBER,
  ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_POSITION_NUMBER,
  ASSIGN_PACK_WIDTH,
  CREATE_PACK,
  CREATE_PACK_FAILED,
  CREATE_PACK_SUCCESS,
  DELETE_PACK,
  DELETE_PACK_FAILED,
  DELETE_PACK_SUCCESS,
  FETCH_PACK,
  FETCH_PACK_FAILED,
  FETCH_PACK_SUCCESS,
  INIT_PACK,
  SPLIT_PACK,
  UPDATE_PACK,
  UPDATE_PACK_FAILED,
  UPDATE_PACK_SUCCESS
} from "redux/model/packs/pack/constants";

export const initPack = () => ({
  type: INIT_PACK,
  payload: {}
});

export const createPack = ({ pack }) => ({
  type: CREATE_PACK,
  payload: { pack }
});

export const createPackSuccess = ({ pack }) => ({
  type: CREATE_PACK_SUCCESS,
  payload: { pack }
});

export const createPackFailed = ({ error }) => ({
  type: CREATE_PACK_FAILED,
  payload: { error }
});

export const fetchPack = ({ packId }) => ({
  type: FETCH_PACK,
  payload: { packId }
});

export const fetchPackSuccess = ({ pack }) => ({
  type: FETCH_PACK_SUCCESS,
  payload: { pack }
});

export const fetchPackFailed = ({ error }) => ({
  type: FETCH_PACK_FAILED,
  payload: { error }
});

export const updatePack = ({ pack, target }) => ({
  type: UPDATE_PACK,
  payload: { pack, target }
});

export const updatePackSuccess = ({ pack }) => ({
  type: UPDATE_PACK_SUCCESS,
  payload: { pack }
});

export const updatePackFailed = ({ error }) => ({
  type: UPDATE_PACK_FAILED,
  payload: { error }
});

export const deletePack = ({ pack }) => ({
  type: DELETE_PACK,
  payload: { pack }
});

export const deletePackSuccess = ({ pack }) => ({
  type: DELETE_PACK_SUCCESS,
  payload: { pack }
});

export const deletePackFailed = ({ error }) => ({
  type: DELETE_PACK_FAILED,
  payload: { error }
});

export const splitPack = ({ pack, newPacks }) => ({
  type: SPLIT_PACK,
  payload: { pack, newPacks }
});

export const assignPackGrossWeight = ({ grossWeight }) => ({
  type: ASSIGN_PACK_GROSS_WEIGHT,
  payload: { grossWeight }
});

export const assignPackHeight = ({ height }) => ({
  type: ASSIGN_PACK_HEIGHT,
  payload: { height }
});

export const assignPackLength = ({ length }) => ({
  type: ASSIGN_PACK_LENGTH,
  payload: { length }
});

export const assignPackWidth = ({ width }) => ({
  type: ASSIGN_PACK_WIDTH,
  payload: { width }
});

export const assignPackDomesticWaybillNumber = ({ domesticWaybillNumber }) => ({
  type: ASSIGN_PACK_DOMESTIC_WAYBILL_NUMBER,
  payload: { domesticWaybillNumber }
});

export const assignPackDomesticWaybillItemNumber = ({ domesticWaybillItemNumber }) => ({
  type: ASSIGN_PACK_DOMESTIC_WAYBILL_ITEM_NUMBER,
  payload: { domesticWaybillItemNumber }
});

export const assignPackItemDescription = ({ itemDescription }) => ({
  type: ASSIGN_PACK_ITEM_DESCRIPTION,
  payload: { itemDescription }
});

export const assignPackQuantity = ({ quantity }) => ({
  type: ASSIGN_PACK_QUANTITY,
  payload: { quantity }
});

export const assignPackUnit = ({ unit }) => ({
  type: ASSIGN_PACK_UNIT,
  payload: { unit }
});

export const assignPackRecipient = ({ recipient }) => ({
  type: ASSIGN_PACK_RECIPIENT,
  payload: { recipient }
});

export const assignPackRecipientMobile = ({ recipientMobile }) => ({
  type: ASSIGN_PACK_RECIPIENT_MOBILE,
  payload: { recipientMobile }
});

export const assignPackRecipientAddress = ({ recipientAddress }) => ({
  type: ASSIGN_PACK_RECIPIENT_ADDRESS,
  payload: { recipientAddress }
});

export const assignPackNote = ({ note }) => ({
  type: ASSIGN_PACK_NOTE,
  payload: { note }
});

export const assignPackWarehouseLocationShelfNumber = ({ warehouseLocationShelfNumber }) => ({
  type: ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_NUMBER,
  payload: { warehouseLocationShelfNumber }
});

export const assignPackWarehouseLocationShelfPositionNumber = ({ warehouseLocationShelfPositionNumber }) => ({
  type: ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_POSITION_NUMBER,
  payload: { warehouseLocationShelfPositionNumber }
});

export const assignPackClient = ({ client }) => ({
  type: ASSIGN_PACK_CLIENT,
  payload: { client }
});

export const assignPackIsRemovePackage = ({ isRemovePackage }) => ({
  type: ASSIGN_PACK_IS_REMOVE_PACKAGE,
  payload: { isRemovePackage }
});

export const assignPackArrivedAt = ({ arrivedAt }) => ({
  type: ASSIGN_PACK_ARRIVED_AT,
  payload: { arrivedAt }
});

export const assignPackVariety = ({ variety }) => ({
  type: ASSIGN_PACK_VARIETY,
  payload: { variety }
});