import { combineReducers } from 'redux';
import formPackBatchUploadReducer from "redux/ui/form/packBatchUpload/formPackBatchUploadReducers";
import formClientReducers from "./client/formClientReducers";
import formPackReducers from "./pack/formPackReducers";
import formLineItemReducers from "redux/ui/form/lineItem/formLineItemReducers";
import formOrderPackReducers from "redux/ui/form/orderPack/formOrderPackReducers";
import formWarehouseReducers from "redux/ui/form/warehouse/formWarehouseReducers";
import formExportDimensionBatchUploadReducers
  from "redux/ui/form/exportDimensionBatchUpload/formExportDimensionBatchUploadReducers";
import formExportInternationalBatchUploadReducers
  from "redux/ui/form/exportInternationalBatchUpload/formExportInternationalBatchUploadReducers";
import formLineAttachmentReducers from "redux/ui/form/lineAttachment/formLineAttachmentReducers";
import formTransactionReducers from "redux/ui/form/transaction/formTransactionReducers";
import formReceivedNoticeReducers from "redux/ui/form/receivedNotice/formReceivedNoticeReducers";
import formPaymentReducers from "redux/ui/form/payment/formPaymentReducers";
import formCouponRuleReducers from "redux/ui/form/couponRule/formCouponRuleReducers";
import formBatchRedeemCouponRuleReducers from "redux/ui/form/batchRedeemCouponRule/formBatchRedeemCouponRuleReducers";
import formWechatNoticeReducers from "redux/ui/form/wechatNotice/formWechatNoticeReducers";
import formReceiptAddressReducers from "redux/ui/form/receiptAddress/formReceiptAddressReducers";

export default combineReducers({
  packBatchUpload: formPackBatchUploadReducer,
  client: formClientReducers,
  pack: formPackReducers,
  lineItem: formLineItemReducers,
  orderPack: formOrderPackReducers,
  warehouse: formWarehouseReducers,
  exportDimensionBatchUpload: formExportDimensionBatchUploadReducers,
  exportInternationalBatchUpload: formExportInternationalBatchUploadReducers,
  lineAttachment: formLineAttachmentReducers,
  transaction: formTransactionReducers,
  receivedNotice: formReceivedNoticeReducers,
  payment: formPaymentReducers,
  couponRule: formCouponRuleReducers,
  batchRedeemCouponRule: formBatchRedeemCouponRuleReducers,
  wechatNotice: formWechatNoticeReducers,
  receiptAddress: formReceiptAddressReducers,
});
