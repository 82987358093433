import {
  ASSIGN_PACK_ARRIVED_AT,
  ASSIGN_PACK_CLIENT,
  ASSIGN_PACK_DOMESTIC_WAYBILL_ITEM_NUMBER,
  ASSIGN_PACK_DOMESTIC_WAYBILL_NUMBER,
  ASSIGN_PACK_GROSS_WEIGHT,
  ASSIGN_PACK_HEIGHT,
  ASSIGN_PACK_IS_REMOVE_PACKAGE,
  ASSIGN_PACK_ITEM_DESCRIPTION,
  ASSIGN_PACK_LENGTH,
  ASSIGN_PACK_NOTE,
  ASSIGN_PACK_QUANTITY,
  ASSIGN_PACK_RECIPIENT,
  ASSIGN_PACK_RECIPIENT_ADDRESS,
  ASSIGN_PACK_RECIPIENT_MOBILE,
  ASSIGN_PACK_UNIT,
  ASSIGN_PACK_VARIETY,
  ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_NUMBER,
  ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_POSITION_NUMBER,
  ASSIGN_PACK_WIDTH,
  FETCH_PACK,
  FETCH_PACK_FAILED,
  FETCH_PACK_SUCCESS,
  INIT_PACK
} from "redux/model/packs/pack/constants";

const INIT_STATE = {
  pack: {},
  loading: true,
  error: null,
};

const packReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PACK:
      return INIT_STATE;

    case FETCH_PACK:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pack: action.payload.pack,
      };

    case FETCH_PACK_FAILED:
      return {
        ...state,
        pack: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_PACK_GROSS_WEIGHT:
      return {
        ...state,
        pack: {
          ...state.pack,
          grossWeight: action.payload.grossWeight,
        }
      }

    case ASSIGN_PACK_HEIGHT:
      return {
        ...state,
        pack: {
          ...state.pack,
          height: action.payload.height,
        }
      }

    case ASSIGN_PACK_LENGTH:
      return {
        ...state,
        pack: {
          ...state.pack,
          length: action.payload.length,
        }
      }

    case ASSIGN_PACK_WIDTH:
      return {
        ...state,
        pack: {
          ...state.pack,
          width: action.payload.width,
        }
      };

    case ASSIGN_PACK_DOMESTIC_WAYBILL_NUMBER:
      return {
        ...state,
        pack: {
          ...state.pack,
          domesticWaybillNumber: action.payload.domesticWaybillNumber,
        }
      };

    case ASSIGN_PACK_DOMESTIC_WAYBILL_ITEM_NUMBER:
      return {
        ...state,
        pack: {
          ...state.pack,
          domesticWaybillItemNumber: action.payload.domesticWaybillItemNumber,
        }
      };

    case ASSIGN_PACK_ITEM_DESCRIPTION:
      return {
        ...state,
        pack: {
          ...state.pack,
          itemDescription: action.payload.itemDescription,
        }
      };

    case ASSIGN_PACK_QUANTITY:
      return {
        ...state,
        pack: {
          ...state.pack,
          quantity: action.payload.quantity,
        }
      };

    case ASSIGN_PACK_UNIT:
      return {
        ...state,
        pack: {
          ...state.pack,
          unit: action.payload.unit,
        }
      };

    case ASSIGN_PACK_RECIPIENT:
      return {
        ...state,
        pack: {
          ...state.pack,
          recipient: action.payload.recipient,
        }
      };

    case ASSIGN_PACK_RECIPIENT_MOBILE:
      return {
        ...state,
        pack: {
          ...state.pack,
          recipientMobile: action.payload.recipientMobile,
        }
      };

    case ASSIGN_PACK_RECIPIENT_ADDRESS:
      return {
        ...state,
        pack: {
          ...state.pack,
          recipientAddress: action.payload.recipientAddress,
        }
      };

    case ASSIGN_PACK_NOTE:
      return {
        ...state,
        pack: {
          ...state.pack,
          note: action.payload.note,
        }
      };

    case ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_NUMBER:
      return {
        ...state,
        pack: {
          ...state.pack,
          warehouseLocationShelfNumber: action.payload.warehouseLocationShelfNumber,
        }
      };

    case ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_POSITION_NUMBER:
      return {
        ...state,
        pack: {
          ...state.pack,
          warehouseLocationShelfPositionNumber: action.payload.warehouseLocationShelfPositionNumber,
        }
      };

    case ASSIGN_PACK_CLIENT:
      return {
        ...state,
        pack: {
          ...state.pack,
          clientId: action.payload.client ? action.payload.client.id : null,
        }
      };

    case ASSIGN_PACK_IS_REMOVE_PACKAGE:
      return {
        ...state,
        pack: {
          ...state.pack,
          isRemovePackage: action.payload.isRemovePackage,
        }
      };

    case ASSIGN_PACK_ARRIVED_AT:
      return {
        ...state,
        pack: {
          ...state.pack,
          arrivedAt: action.payload.arrivedAt,
        }
      };

    case ASSIGN_PACK_VARIETY:
      return {
        ...state,
        pack: {
          ...state.pack,
          variety: action.payload.variety,
        }
      };

    default:
      return { ...state };
  }
};

export default packReducers;
